import React, { Dispatch, SetStateAction } from 'react';
import { SketchPicker } from "react-color";

interface ColorPickerInt{
    color: string,
    setColor: Dispatch<SetStateAction<string>>
}

export default function ColorPicker({color, setColor}: ColorPickerInt) {

    const colorHandler = (color: string) => {
        setColor(color);
    }

    return (
        <SketchPicker onChangeComplete={(e: any) => colorHandler(e.hex)} color={color}/>
    )
}
