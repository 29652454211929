import React from 'react';

import Menu from '../Menu/Menu';

import { HeaderInt } from '@/settings/interfaces';

import styles from "./Header.module.scss";

export default function Header({sticky=false}:HeaderInt){
	return(
		<header className={`${styles["DAS-header"]} ${sticky ? styles["DAS-header_sticky"] : ''}`}>
			<div className='container center'>
				<span>Draig's telegram market</span>
			</div>
		</header>
	)
}
