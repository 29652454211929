export class FileDownload{

    private filename: string;

    constructor(filename: string){
        this.filename = "file.txt";
    }

    saveFileName(res: any, headerName: string, partOfHeaderString: string){
        const fileName = res.headers.get(headerName).split(';').find((n: string) => n.includes(partOfHeaderString)).replace(partOfHeaderString, '').trim();
        this.filename = fileName ?? "file.txt";
    }

    saveFile(data: Blob){
        const url: string = window.URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", decodeURI(this.filename));
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}