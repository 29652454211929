const DAS_endpoints = {
    TOKEN_LOGIN: "auth/token/login/",
    TOKEN_LOGOUT: "auth/token/logout/",

    PASSWORD_CHANGE: "auth/users/set_password/",
    PASSWORD_RESET: "auth/users/reset_password/",
    PASSWORD_RESET_CONFIRM: "auth/users/reset_password_confirm/",
    CREATE_SHOP: "api/shop",
    GET_MY_SHOPS: 'api/shop/get',
    VALIDATE_SHOP: 'api/shop/create'
}

export default DAS_endpoints;