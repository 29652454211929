import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

import Button from '@/ui/Button/Button';

import useTelegram from '@/hooks/useTelegram';
import ROUTES_LIST from '@/settings/routes';

export default function MainModule() {

    const nav = useNavigate();

    const tgApi = useTelegram();

    const navigateTo = (path: string) => {
        nav(path);
    }

    return (
        <div className="container center" style={{ padding: '50px' }}>
        <div className='grid-one center'>
            {/*@ts-ignore */}
            {(tgApi) ? (<span>Hello {tgApi.user.first_name} {tgApi.user.last_name}</span>) : null}
            <Button
                mode='link'
                path='/create-shop'
                size='middle'
                text={"Создать магазин"}
                callback={() => navigateTo(ROUTES_LIST.createShop)}
            />
            {/* <Button
                mode='link'
                path='/my-shops'
                size='middle'
                text={"Посмотреть мои магазины"}
                callback={() => navigateTo(ROUTES_LIST.shops)}
            /> */}
            <Button
                size='middle'
                text={"Тех. поддержка"}
            />
        </div>
    </div>
    )
}
