import RouterPath from "./RouterPath";

import "@/styles/global.scss";
import "@/styles/globals.css";
function App() {
    return (
        <RouterPath/>
    );
}

export default App;
