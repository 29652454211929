import { Outlet } from "react-router";
import React from 'react';

import Header from '@/components/basic/Header/Header';
import Footer from '@/components/basic/Footer/Footer';

import { MainLayoutInt } from '@/settings/interfaces';

export default function MainLayout({ enableFooter = true }: MainLayoutInt) {
    return (
        <div className='content'>
            <Header sticky={true} />
            <main className='container content center'>
                <Outlet/>
            </main>
            {(enableFooter) ? <Footer /> : null}
        </div>
    );
}
