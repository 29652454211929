import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Input from '@/ui/Input/Input';
import Button from '@/ui/Button/Button';
import ColorPicker from '@/ui/ColorPicker/ColorPicker';
import FileInput from '@/ui/FileInput/FileInput';
import Select from '@/ui/Select/Select';
import Checkbox from '@/ui/Checkbox/Checkbox';

import useChangeColor from '@/hooks/useChangeColor';
import useTelegram from '@/hooks/useTelegram';
import DAS_HTTP from '@/tools/DAS_HTTP';
import DAS_endpoints from '@/settings/DAS_endpoints';

import styles from './CreateShopForm.module.scss';

export default function CreateShopForm() {
	const [shopData, setShopData] = useState({});
	const [stage, setStage] = useState(1);
	const [file, setFile] = useState('');
	const [fileError, setFileError] = useState('');
	const [activeTab, setActiveTab] = useState(0);

	const formFileRef = useRef<any>();

	const navigate = useNavigate();

	const VALIDATE_TOKEN_REGEXP = /(\d{10}:\S{35})/;
	const VALIDATE_COLOR_REGEXP = /#(\d|[A-Fa-f]){6}/;

	const labels = ['в блочном варианте', 'в табличном варианте'];

	const form = useFormik({
		initialValues: {
			shop_name: '',
			shop_token: '',
		},
		validationSchema: yup.object().shape({
			shop_name: yup
				.string()
				.min(2, 'Название магазина слишком короткое')
				.max(40, "Название магазина слишком длинное")
				.required('Название магазина обязательно'),
			shop_token: yup
				.string()
				.min(46, "Токен должен содержать 46 символов")
				.max(46, "Токен должен содержать 46 символов")
				.matches(VALIDATE_TOKEN_REGEXP, "Некорректный токен")
				.required('Токен обязателен'),
		}),
		onSubmit: async (values: any) => {
			console.log(values);
			setShopData({
				...shopData,
				...values,
			});
			setStage(2);
		},
	});

	const formSettings = useFormik({
		initialValues: {
			primary_color: '',
		},
		validationSchema: yup.object().shape({
			primary_color: yup.string().min(7, "Неправильный цвет").max(7, "Неправильный цвет").matches(VALIDATE_COLOR_REGEXP, "Некорректный токен").required('Выбор цвета обязателен'),
		}),
		onSubmit: async (values: any) => {
			console.log(values);
			setShopData({
				...shopData,
				...values,
			});
			setStage(3);
		},
	});

	const formFile = useFormik({
		initialValues: {
			shop_file: '',
		},
		validationSchema: yup.object().shape({
			shop_file: yup.string().required('Файл обязателен'),
		}),
		onSubmit: async (values: any) => {
			console.log(values);
			if (file) {
				const formData = new FormData();
				for (let item of Object.entries(shopData)) {
					//@ts-ignore
					formData.append(item[0], item[1]);
				}
				//append chatId
				//@ts-ignore
				formData.append('user_id', tgApi?.user?.id ?? String(5998998054));
				formData.append('shop_file', values.shop_file);
				DAS_HTTP.request({
					method: 'POST',
					formEnable: true,
					url: DAS_endpoints.VALIDATE_SHOP,
					data: formData,
					getAll: true,
					checkHeaders: true,
				}).then((res: any) => {
					if (res.hasOwnProperty("message")){
						setFileError(res.message);
					}
					else{
						setStage(5);
					}
				});
			}
		},
	});

	const formCatalogGridType = useFormik({
		initialValues: {
			// catalog_grid_type: 'grid',
			address: "",
			work_time_from: "",
			work_time_to: "",
			delivery: true,
			order_time: false,
			order_time_from: "",
			order_time_to: "",
		},
		validationSchema: yup.object().shape({
			//catalog_grid_type: yup.string().required("укажите параметр"),
			address: yup.string().required("Укажите адрес"),
			work_time_from: yup.string().required("Укажите время работы"),
			work_time_to: yup.string().required("Укажите время работы"),
			delivery: yup.boolean(),
			order_time: yup.boolean(),
			order_time_from: yup.string().when("order_time", { is: true, then: (validationSchema) => validationSchema.required() }),
			order_time_to: yup.string().when("order_time", { is: true, then: (validationSchema) => validationSchema.required() }),
		}),
		onSubmit: async (values: any) => {
			console.log(values);
			setShopData({
				...shopData,
				...values,
			});
			setStage(4);
		},
	});

	const tgApi = useTelegram();

	const [firstColor, setFirstColor] = useChangeColor(
		formSettings,
		'primary_color'
	);

	useEffect(() => {
		if (file) {
			formFileRef.current.click();
		}
		else{
			setFileError("Загрузите файл");
		}
	}, [file]);

	useEffect(() => {
		setFirstColor(formSettings.values.primary_color);
	}, [formSettings.values.primary_color]);

	return (
		<div className='container content'>
			{stage === 1 && (
				<form
					className={styles['create-form']}
					onSubmit={form.handleSubmit}>
					<h2 className={styles["create-form-title"]}>Основные настройки</h2>
					{(tgApi) ? (<span style={{marginBottom: "10px"}}>Создай свой магазин, {tgApi?.user?.first_name} {tgApi?.user?.last_name ?? ""}</span>) : null}
					<Input
						type="text"
						label="Название магазина"
						placeholder="Введите название"
						form={form}
						id="shop_name"
						name="shop_name"
					/>
					<Input
						type="text"
						label="Токен магазина"
						placeholder="Введите токен"
						form={form}
						id="shop_token"
						name="shop_token"
					/>
					<Button type={'submit'} text={'Далее'} />
				</form>
			)}
			{stage === 2 && (
				<form
					className={styles['create-form']}
					onSubmit={formSettings.handleSubmit}>
					<h2 className={styles["create-form-title"]}>Стилизация</h2>
					<div className="grid-one">
						<div className="">
							<Input
								// inputChange={(e: any) => setFirstColor(e)}
								type="text"
								label="Основной цвет"
								placeholder="Введите цвет"
								form={formSettings}
								id="primary_color"
								name="primary_color"
							/>
							<ColorPicker
								color={firstColor}
								setColor={setFirstColor}
							/>
						</div>
					</div>
					<div style={{ display: "flex", justifyContent: "center", marginTop: "15px" }}>

					</div>
					<Button type={'submit'} text={'Далее'} />
				</form>
			)}

			{stage === 3 && (
				<form
					className={styles['create-form']}
					onSubmit={formCatalogGridType.handleSubmit}
				>
					<h2 className={styles["create-form-title"]}>Настройки магазина</h2>
					<Input
						type="text"
						label="Адрес"
						placeholder="Введите адрес"
						form={formCatalogGridType}
						id="address"
						name="address"
					/>
					<div className='grid-two' style={{ width: "100%" }}>
						<Input
							type="time"
							label="Начало работы"
							placeholder="Время начала работы"
							form={formCatalogGridType}
							id="work_time_from"
							name="work_time_from"
						/>
						<Input
							type="time"
							label="Окончание работы"
							placeholder="Время окончания работы"
							form={formCatalogGridType}
							id="work_time_to"
							name="work_time_to"
						/>
					</div>
					{/* <Select
						items={labels}
						id={'catalog_grid_type'}
						name={'catalog_grid_type'}
						except={true}
						selectedItemKey={activeTab}
						error={true}
						callback={getActiveTab}
						label={"Отображать свойства товаров"}
					/> */}
					<div className='grid-one pos-left' style={{ width: "100%" }}>
						<Checkbox
							label='Доставка'
							id='delivery'
							name='delivery'
							form={formCatalogGridType}
						/>
						<Checkbox
							label='Ограничить время заказа'
							id='order_time'
							name='order_time'
							form={formCatalogGridType}
						/>
					</div>
					{(formCatalogGridType.values.order_time) &&
						<div className='grid-two' style={{ width: "100%" }}>
							<Input
								type="time"
								label="Время начала заказов"
								placeholder="Время начала заказов"
								form={formCatalogGridType}
								id="order_time_from"
								name="order_time_from"
							/>
							<Input
								type="time"
								label="Время окончания заказов"
								placeholder="Время окончания заказов"
								form={formCatalogGridType}
								id="order_time_to"
								name="order_time_to"
							/>
						</div>
					}
					<Button type={'submit'} text={'Далее'} />
				</form>
			)}


			{stage === 4 && (
				<form
					className={styles['create-form']}
					onSubmit={formFile.handleSubmit}>
					<h2 className={styles["create-form-title"]}>Загрузка товаров</h2>
					<FileInput
						accept="application/xml, text/xml"
						id={'shop_file'}
						name={'shop_file'}
						form={formFile}
						setFile={setFile}
					/>
					<div style={{marginTop: "5px",marginBottom: "5px"}}>
						{fileError}
					</div>
					<button
						style={{ display: 'none' }}
						type="submit"
						ref={formFileRef}>
						Send
					</button>
					<Button type={'submit'} text={'Далее'} />
				</form>
			)}
			{stage === 5 && (
				<form
					className={styles['create-form']}
					onSubmit={formFile.handleSubmit}>
					<h2 className={styles["create-form-title"]}>Завершение</h2>
					<div style={{marginTop: "25px",marginBottom: "25px"}}>
						Ваш магазин успешно создан!
					</div>
					<Button type={'button'} text={'На главную страницу'} callback={() => navigate("/")}/>
				</form>
			)}
		</div>
	);
}
