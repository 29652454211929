import React from 'react';

import ErrorBox from '@/ui/ErrorBox/ErrorBox';

import { CheckboxInt } from '@/settings/interfaces';

import styles from './Checkbox.module.scss';

export default function Checkbox({
        label = "", 
        mode = "checkbox",
        checked = false, 
        id,
        name, 
        form, 
        error = false,
        callback,
        inputChange,
    }: CheckboxInt){

    return(
        <div className={styles["DAS-checkbox"]}>
            <div className={styles["DAS-checkbox-container"]}>
                <input 
                    type="checkbox" 
                    name={name}
                    id={id}
                    className={styles[`DAS-checkbox-${mode}`]} 
                    checked={(form) ? form.values[name as keyof typeof form.values] : checked}
                    onChange={(form) ? form.handleChange: (inputChange) ? (e: any) => inputChange(e.target.checked) : callback}
                    value=""
                />
                <label className={styles["DAS-checkbox-label"]}>
                    {label}
                </label>
            </div>
            <div className={styles["DAS-checkbox-error"]}>
                <ErrorBox
                    error={error}
                    form={form}
                    name={name}
                />
            </div>
        </div>
    );
}
