import { now } from "lodash";
import { SEARCH_SYMBOLS } from "@/settings/config";
import { DAS_symbols } from "@/settings/interfaces";
export default class DAS_DateTime{

    static currentDate: Date = new Date();

    static getCurrentYear(): number{
        const date: Date = new Date();
        return DAS_DateTime.currentDate.getFullYear();
    }

    static getMonthWithPrefix(value: number){
        return value < 10 ? `0${value}` : value;
    }

    static getValidationYear(currentYear: number): RegExp{
        return new RegExp(`^(0?[1-9]|[12][0-9]|3[01])[-\.](0?[1-9]|1[012])[-\.](19[5-9][0-9]|20([01][0-9]|2[0-${currentYear}]))$`);
    }

    //Конвертация даты
    static convertTo(value: string, reverse: boolean = true, symbolTo: DAS_symbols = "-"): string{
        const symbol = value.match(SEARCH_SYMBOLS);
        const returnValue = value.split((symbol) ? symbol[0] : ".");
        if (reverse){
            returnValue.reverse();
        }
        return returnValue.join(symbolTo);
    }

    //Проверка возраста
    static ageValidation(value: string, age: number){ //value format: 01./-01./-2023
        const formatValue = new Date(DAS_DateTime.convertTo(value));
        const currentYear = DAS_DateTime.getCurrentYear();
        const validateDate = new Date();
        validateDate.setFullYear(currentYear - age);
        if (value !== undefined && String(value).slice(9, 10) !== "_"){
            return validateDate > new Date(formatValue);
        }
        return false;
    }

    //Проверка правильности введённой даты в соответствии с календарём
    static dateValidation(value: string){
        if (value !== undefined && String(value).slice(9, 10) !== "_"){
            const formatValue = DAS_DateTime.convertTo(value);
            const date = new Date(String(formatValue));
            return(
                `${DAS_DateTime.getMonthWithPrefix(date.getDate())}.${DAS_DateTime.getMonthWithPrefix(
                    date.getMonth() + 1
                )}.${date.getFullYear()}` === String(value)
            );
        }
        return false;
    }
}