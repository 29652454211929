import React from 'react';

import DAS_DateTime from '@/tools/DAS_DateTime';

import styles from "./Footer.module.scss";

export default function Footer(){
    return(
        <footer className={styles["DAS-footer"]}>
            <div className='container center'>
                Draig solution's © {DAS_DateTime.getCurrentYear()}
			</div>
        </footer>
    )
}
