//Api config
export const SERVER_URL_IMAGE: string = "http://62.217.179.26:5000/" //Слэш на конце
export const SERVER_URL_API: string = "http://62.217.179.26:5000/" //Слэш на конце

//config
export const PAGINATION_COUNT: number = 7;
export const MAX_CARDS: number = 6;
export const ENABLE_ADAPTIVE_RESIZE: boolean = true;
export const ENABLE_SWITCH_THEME: boolean = true;
export const ENABLE_TRANSLATION: boolean = true;

//validation
export const PHONE_REGEXP: RegExp = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;
export const SEARCH_SYMBOLS: RegExp = /([./-])/g;