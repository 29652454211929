import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React from 'react';

import MainPage from "@/pages/MainPage";
import MainLayout from "@/layouts/MainLayout/MainLayout";
import MainModule from "@/components/project/MainModule/MainModule";
import CreateShop from "./pages/CreateShop";
import ShopsList from "./pages/ShopsList";

import ROUTES_LIST from "@/settings/routes";

export default function RouterPath() {
    return(
        <Router>
            <Routes>
                <Route path={ROUTES_LIST.index} element={<MainLayout />}>
                    <Route path={ROUTES_LIST.index} element={<MainPage />}/>
                    <Route path={ROUTES_LIST.createShop} element={<CreateShop />}/>
                    <Route path={ROUTES_LIST.shops} element={<ShopsList />}/>
                </Route>
            </Routes>
        </Router>
    )
}
