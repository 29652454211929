import React from 'react';

import { ErrorBoxInt } from '@/settings/interfaces';

import styles from './ErrorBox.module.scss';

export default function ErrorBox({error, name, form}: ErrorBoxInt){

    return(
        <>
            {(error && form) &&
                (form.errors[name as keyof typeof form.errors] && form.touched[name as keyof typeof form.touched]) ? (
                    <div className={styles["DAS-error-box"]}>
                        {form.errors[name as keyof typeof form.errors]}
                    </div>
            ) : null}
        </>
    )
}
