import { useState, useEffect, Dispatch, SetStateAction } from 'react';

export default function useChangeColor(form: any, formValue: string): [string, Dispatch<SetStateAction<string>>]{

    const [color, setColor] = useState<string>("");

    useEffect(() => {
        if (color !== ""){
            console.log(123);
            form.values[formValue] = color;
        }
    }, [color]);

    return [color, setColor];
}