import { useRef, Ref } from 'react';

import Button from '@/ui/Button/Button';
import ErrorBox from '@/ui/ErrorBox/ErrorBox';

import { FileInputInt } from '@/settings/interfaces';
import Functions from '@/tools/Functions';

import styles from "./FileInput.module.scss";

export default function FileInput({ id, accept, name, form, disabled = false, error = true, multiple = false, text = "Выберите файл", setFile}: FileInputInt){

    const inputRef: any = useRef();

    const changeFiles = () => {
        const fileOutput = [Functions.filesValidation(inputRef.current.files, accept, multiple)];
        form.values[name as keyof typeof form.values] = fileOutput[0];
        form.validateForm();
        setFile([fileOutput[0]]);
    }

    return(
        <div className={styles["file-input"]}>
            <input ref={inputRef} type="file" multiple={multiple} id={id} datatype={"png"} accept={accept} name={name} onChange={changeFiles}/>
            <Button
                text={text}
                size="middle"
                callback={() => inputRef.current.click()}
                disabled={disabled}
            />
            <ErrorBox
                error={error}
                form={form}
                id={id}
            />
        </div>
    )
}
