import React from 'react';

import { ButtonInt } from '@/settings/interfaces';

import styles from './Button.module.scss';

export default function Button(
    {
        path = '/',
        type = "button",
        text = "",
        size = "middle",
        border = false,
        background = true,
        invert = false,
        disabled = false,
        className = "",
        style = {},
        insertElement,
        callback = () => { }
    }: ButtonInt) {

    return (
        <button
            className={`
        ${styles['DAS-button']} 
        ${styles[`DAS-button-${size}`]} 
        ${border ? styles['DAS-button-border'] : ''} 
        ${invert ? styles['DAS-button-invert'] : ''} 
        ${background ? '' : 'non-background'} 
        ${disabled ? styles['DAS-button-disabled'] : ''}       
        ${className}`}
            type={type}
            style={style}
            disabled={disabled}
            onClick={callback}>
            {text || insertElement}
        </button>
    )
}
