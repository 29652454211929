import { useEffect, useRef } from 'react';

export default function useTelegram() {

    let tgApi = useRef<any>(false);

    useEffect(() => {
		if (window !== undefined){
			//@ts-ignore
			tgApi.current = window.Telegram?.WebApp ?? false;
			console.log(tgApi);
		}
	});
    return tgApi.current;
}
